import { GAMETYPES } from "../types/types"

export const GAMENAMES: any = {
    [GAMETYPES.LOL]: 'League of Legends',
    [GAMETYPES.COUNTER_STRIKE]: 'Counter-Strike 2',
    [GAMETYPES.MINECRAFT]: 'Minecraft',
    [GAMETYPES.ROCKET_LEAGUE]: 'Rocket League',
    [GAMETYPES.VALORANT]: 'VALORANT',
    [GAMETYPES.R6]: 'Rainbow Six Siege'
}

export const Roles: any = {
    "Reservist": "Záložník",
    "Member": "Člen",
    "Captain": "Kapitán"
}

export const Ranks: any ={
    [GAMETYPES.COUNTER_STRIKE]:[
        'Žádný rank',
        'Silver I',
        'Silver II',
        'Silver III',
        'Silver IV',
        'Silver Elite',
        'Silver Elite Master',
        'Gold Nova I',
        'Gold Nova II',
        'Gold Nova III',
        'Gold Nova Master',
        'Master Guardian',
        'Master Guardian 2',
        'Master Guardian Elite',
        'Distinguished Master Guardian',
        'Legendary Eagle',
        'Legendary Eagle Master',
        'Supreme Master First Class',
        'Global Elite'
    ],
    [GAMETYPES.MINECRAFT]:[
        'Žádný rank',
    ],
    [GAMETYPES.LOL]:[
        'Žádný rank',
        'Iron IV',
        'Iron III',
        'Iron II',
        'Iron I',
        'Bronze IV',
        'Bronze III',
        'Bronze II',
        'Bronze I',
        'Silver IV',
        'Silver III',
        'Silver II',
        'Silver I',
        'Gold IV',
        'Gold III',
        'Gold II',
        'Gold I',
        'Platinum IV',
        'Platinum III',
        'Platinum II',
        'Platinum I',
        'Emerald IV',
        'Emerald III',
        'Emerald II',
        'Emerald I',
        'Diamond IV',
        'Diamond III',
        'Diamond II',
        'Diamond I',
        'Master',
        'Grandmaster',
        'Challenger'
    ],
    [GAMETYPES.ROCKET_LEAGUE]:[
        'Žádný rank',
        'Bronze I',
        'Bronze II',
        'Bronze III',
        'Silver I',
        'Silver II',
        'Silver III',
        'Gold I',
        'Gold II',
        'Gold III',
        'Platinum I',
        'Platinum II',
        'Platinum III',
        'Diamond I',
        'Diamond II',
        'Diamond III',
        'Champion I',
        'Champion II',
        'Champion III',
        'Grand Champion I',
        'Grand Champion II',
        'Grand Champion III',
        'Supersonic Legend'
    ],
    [GAMETYPES.VALORANT]:[
        'Žádný rank',
        'Iron 1',
        'Iron 2',
        'Iron 3',
        'Bronze 1',
        'Bronze 2',
        'Bronze 3',
        'Silver 1',
        'Silver 2',
        'Silver 3',
        'Gold 1',
        'Gold 2',
        'Gold 3',
        'Platinum 1',
        'Platinum 2',
        'Platinum 3',
        'Diamond 1',
        'Diamond 2',
        'Diamond 3',
        'Ascendant 1',
        'Ascendant 2',
        'Ascendant 3',
        'Immortal 1',
        'Immortal 2',
        'Immortal 3',
        'Radiant'
    ],
    [GAMETYPES.R6]:[
        'Žádný rank',
        'Copper 1',
        'Copper 2',
        'Copper 3',
        'Copper 4',
        'Copper 5',
        'Bronze 1',
        'Bronze 2',
        'Bronze 3',
        'Bronze 4',
        'Bronze 5',
        'Silver 1',
        'Silver 2',
        'Silver 3',
        'Silver 4',
        'Silver 5',
        'Gold 1',
        'Gold 2',
        'Gold 3',
        'Gold 4',
        'Gold 5',
        'Platinum 1',
        'Platinum 2',
        'Platinum 3',
        'Platinum 4',
        'Platinum 5',
        'Emerald 1',
        'Emerald 2',
        'Emerald 3',
        'Emerald 4',
        'Emerald 5',
        'Diamond 1',
        'Diamond 2',
        'Diamond 3',
        'Diamond 4',
        'Diamond 5',
        'Champions'
    ]
}